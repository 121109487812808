import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/apps/accounts",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/apps/accounts",
        name: "apps-accounts",
        component: () => import("@/views/apps/accounts/AccountList.vue"),
      },
      {
        path: "/apps/central-exclude-keywords",
        name: "apps-central-exclude-keywords",
        component: () =>
          import(
            "@/views/apps/centralExcludeKeywords/CentralExcludeKeywordList.vue"
          ),
      },

      {
        path: "/apps/text-checker",
        name: "apps-text-checker",
        component: () => import("@/views/apps/text_checker/text_checker.vue"),
      },
      {
        path: "/apps/source-info",
        name: "apps-source-info",
        component: () => import("@/views/apps/sources/SourceInfo.vue"),
      },
      {
        path: "/apps/source-list",
        name: "apps-source-list",
        component: () => import("@/views/apps/dataFeed/SourceIndex.vue"),
      },
      {
        path: "/apps/job-monitoring",
        name: "apps-job-monitoring",
        component: () => import("@/views/apps/jobMonitoring/JobMonitoring.vue"),
      },
      {
        path: "/apps/data-feed",
        name: "apps-data-feed",
        component: () => import("@/views/apps/dataFeed/Index.vue"),
      },
      {
        path: "/apps/sentiment-keywords",
        name: "apps-sentiment-keywords",
        component: () => import("@/views/apps/sentiment/SentimentKeyword.vue"),
      },
      {
        path: "/apps/fanpage",
        name: "apps-fanpage",
        component: () => import("@/views/apps/fanpage/fanpageList.vue"),
      },

      {
        path: "/apps/influencer",
        name: "apps-influencer",
        component: () => import("@/views/apps/influencer/influencerList.vue"),
      },
      {
        path: "/apps/ocr",
        name: "apps-ocr",
        component: () => import("@/views/apps/ocr/OcrPage.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
