import ApiService from "@/core/services/ApiService";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";

interface Account {
  staffs: unknown[];
  accounts: unknown[];
}

@Module
export default class AccountModule extends VuexModule implements Account {
  staffs = [];
  accounts = [];
  editAccountInfo = null;
  sources = [];

  get allSources() {
    return this.sources;
  }

  get staffInfos() {
    return this.staffs;
  }

  get accountInfos() {
    return this.accounts;
  }

  get accountInfo() {
    return this.editAccountInfo;
  }

  @Action
  async [Actions.EDIT_ACCOUNT_BY_ID](account) {
    return ApiService.getAccountById(account).then((res) => {
      const accountResponse = res.data.data || null;
      this.context.commit(Mutations.SET_EDIT_ACCOUNT, accountResponse);
    });
  }

  @Action
  async [Actions.GET_ALL_ACCOUNTS](payload) {
    return ApiService.getAccounts(payload)
      .then((res) => {
        const accountResponse = res.data.data || [];
        this.context.commit(Mutations.SET_ACCOUNTS, accountResponse);
      })
      .catch(() => {
        this.context.commit(Mutations.SET_ACCOUNTS, []);
      });
  }

  @Action
  async [Actions.GET_ALL_STAFFS]() {
    return ApiService.getAllStaff().then((res) => {
      const staffResponse = res.data.data || [];
      this.context.commit(Mutations.SET_STAFFS, staffResponse);
    });
  }

  @Action
  async [Actions.GET_ALL_SOURCE]() {
    return ApiService.getAllSource().then((res) => {
      const sources = res.data.message || [];
      this.context.commit(Mutations.SET_ALL_SOURCE, sources);
    });
  }

  @Mutation
  [Mutations.SET_ALL_SOURCE](payload): void {
    this.sources = payload;
  }

  @Mutation
  [Mutations.SET_ACCOUNTS](payload): void {
    this.accounts = payload;
  }

  @Mutation
  [Mutations.SET_STAFFS](payload) {
    this.staffs = payload;
  }

  @Mutation
  [Mutations.SET_EDIT_ACCOUNT](payload) {
    this.editAccountInfo = payload;
  }
}
