import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(
      `${resource}${slug ? "/" + slug : ""}`
    );
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    data,
    params?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, data, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    data,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, data, config);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(
    resource: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource, config);
  }

  // api below

  public static auth(username: string, password: string) {
    return this.post("/services/login", { username, password });
  }

  public static verifyAuth() {
    return this.get("/services/verify");
  }

  public static getAllStaff() {
    return this.get("/ldap/users");
  }

  public static getAccounts({ page = 0, limit = 10, status, name = "" }) {
    return this.query("/accounts/get-accounts", {
      params: { page, limit, status, name },
    });
  }

  public static getAccountById(account: string) {
    return this.query("/accounts/get-by-id", { params: { account } });
  }

  public static addAccount({ name, status, staffs, color, quota, sources }) {
    return this.post("/accounts/add", {
      name,
      status,
      staffs,
      color,
      quota,
      sources,
    });
  }

  public static updateAccount({ name, status, staffs, color, quota, sources }) {
    return this.put("/accounts/update-by-id", {
      name,
      status,
      staffs,
      color,
      quota,
      sources,
    });
  }
  public static uploadimgAccount({ id, imgacc }) {
    const data = new FormData();
    data.append("file", imgacc);
    return this.post(`/corpus/account/upload-account-image?id=${id}`, data);
  }
  public static deleteAccountById({ account }) {
    return this.delete("/accounts/delete-by-id", {
      params: { account },
    });
  }

  public static getAllDOMJob() {
    return this.get(`/dom-job/job/get-all-job`);
  }

  public static cancelDOMJobService() {
    return this.post(`/portainer/restart-job-monitoring-service`, {});
  }

  public static runDOMJob(
    account: string,
    jobType: string,
    sinceDate: string,
    untilDate: string,
    categoryLv0Filter: Array<string>,
    categoryLv1Filter: Array<string>,
    categoryLv2Filter: Array<string>,
    categoryLv3Filter: Array<string>,
    categoryLv4Filter: Array<string>,
    categoryLv5Filter: Array<string>,
    categoryLv6Filter: Array<string>,
    categoryLv7Filter: Array<string>,
    categoryLv8Filter: Array<string>,
    categoryLv9Filter: Array<string>,
    toAccount: string,
    fromAccount: string
  ) {
    let slug = "";
    switch (jobType) {
      case "EXCLUDE":
        slug = `run-exclude-job`;
        break;
      case "CATEGORIZE":
        slug = `run-categorize-job`;
        break;
      case "ENGAGEMENT":
        slug = `run-engagement-update-job`;
        break;
      case "SENTIMENT":
        slug = `run-sentiment-job`;
        break;
      case "INCLUDE":
        slug = `run-include-data`;
        break;
      default:
        break;
    }
    const data = {};
    data["account"] = account;
    if (sinceDate && untilDate) {
      data["sinceDate"] = sinceDate;
      data["untilDate"] = untilDate;
    }
    if (categoryLv0Filter) {
      data["categoryLv0Filter"] = categoryLv0Filter;
    }
    if (categoryLv1Filter) {
      data["categoryLv1Filter"] = categoryLv1Filter;
    }
    if (categoryLv2Filter) {
      data["categoryLv2Filter"] = categoryLv2Filter;
    }
    if (categoryLv3Filter) {
      data["categoryLv3Filter"] = categoryLv3Filter;
    }
    if (categoryLv4Filter) {
      data["categoryLv4Filter"] = categoryLv4Filter;
    }
    if (categoryLv5Filter) {
      data["categoryLv5Filter"] = categoryLv5Filter;
    }
    if (categoryLv6Filter) {
      data["categoryLv6Filter"] = categoryLv6Filter;
    }
    if (categoryLv7Filter) {
      data["categoryLv7Filter"] = categoryLv7Filter;
    }
    if (categoryLv8Filter) {
      data["categoryLv8Filter"] = categoryLv8Filter;
    }
    if (categoryLv9Filter) {
      data["categoryLv9Filter"] = categoryLv9Filter;
    }
    if (toAccount && fromAccount) {
      data["to-account"] = toAccount;
      data["from-account"] = fromAccount;
    }

    return this.query(`/dom-job/job/${slug}`, {
      params: data,
    });
  }

  public static getSourcesStat() {
    return this.get("/crawler-tool-server/source-stat");
  }

  public static getFanpageInfos() {
    return this.get("/crawler-tool-server/fanpage-infos");
  }

  public static getYoutubeChannels() {
    return this.get("/corpusV2/youtube/get-all-channels");
  }

  public static getInstagramUsers() {
    return this.get("/corpusV2/instagram/get-all-users");
  }

  public static updateCrawlerSetting(fanpage: {
    levelOfRunFrequency: number;
    numCommentPerPost: number;
    id: string;
  }) {
    return this.put(
      `/corpus/fanpage-info/${fanpage.id}/update-crawler-setting`,
      {
        levelOfRunFrequency: fanpage.levelOfRunFrequency,
        numCommentPerPost: fanpage.numCommentPerPost,
      }
    );
  }

  public static getAllRunPost({
    page = 0,
    limit = 10,
    q,
    type,
    status,
  }: {
    page: number;
    limit: number;
    q: string;
    type: string;
    status?: string;
  }) {
    return this.query("/crawler-tool-server/run-post", {
      params: { page, limit, q, type, status },
    });
  }

  public static runPost(run: {
    link: string;
    retryId?: string;
    commentLimit?: number;
    keyword?: string;
    username: string;
  }) {
    return this.post("/crawler-tool-server/run-post", {
      link: run.link,
      id: run.retryId,
      commentLimit: run.commentLimit,
      forceKeyword: run.keyword,
      username: run.username,
    });
  }

  public static runAccount(run: {
    link: string;
    retryId?: string;
    from: number;
    to: number;
  }) {
    return this.post("/crawler-tool-server/run-account", {
      link: run.link,
      id: run.retryId,
      from: run.from,
      to: run.to,
    });
  }

  public static cancelRunAccount(id: string) {
    return this.post("/crawler-tool-server/run-account/cancel", {
      id,
    });
  }

  // ldap
  public static getLDAPBrandInfo(name) {
    return this.get(`/ldap/brands/${name}`);
  }

  public static performMatching(account, source, message, username) {
    return this.post("/crawler-tool-server/perform-matching", {
      accounts: account,
      source: source,
      message: message,
      username: username,
    });
  }

  public static crossCheck(link: string, accounts: string[]) {
    return this.post("/crawler-tool-server/cross-checker", {
      accounts,
      link: link,
    });
  }

  public static resolveMatching(link: string, accounts: string[]) {
    return this.post("/crawler-tool-server/resolve-matching", {
      accounts,
      link: link,
    });
  }

  public static searchAccount({ page = 0, sizePerPage = 10, name, status }) {
    return this.post("/corpus/account/search", {
      page,
      sizePerPage,
      name,
      status,
      sortDirection: "ASC",
      sortField: "CREATED_AT",
    });
  }

  public static getAllSource() {
    return this.get(
      "/corpus/source/get-all-source"
      // {
      //   params: {
      //     crawlable: true,
      //   },
      // }
    );
  }

  public static cloneAccount(data: {
    destinationAccountName: string;
    sourceAccountId: string;
  }) {
    return this.post("/accounts/clone", {
      destinationAccountName: data.destinationAccountName,
      sourceAccountId: data.sourceAccountId,
    });
  }

  public static resolveNews(link: string) {
    return this.post("/crawler-tool-server/resolve-news", { link });
  }

  public static getAllSourceAccount() {
    return this.get("/corpus/source/get-all-source-account");
  }

  public static submitNews(data) {
    return this.post("/crawler-tool-server/news/submit", data);
  }

  public static addSource(data: {
    id: string;
    name: string;
    color: string;
    crawlable: boolean;
  }) {
    return this.post("/corpus/misc/add-source", data);
  }

  public static exportAccountUsage(data: {
    account: string;
    since: string;
    until?: string;
    graularity?: string;
  }) {
    return this.query(`/accounts/usage-exporter`, {
      params: data,
      responseType: "arraybuffer",
    });
  }

  public static getSentimentKeyword({ skip = 0, limit = 10 }) {
    return this.query("/corpus/dictionary/get-sentiment", {
      params: {
        limit,
        skip,
      },
    });
  }

  public static addSentimentKeyword(data: { id: string; value: number }) {
    return this.post("/corpus/dictionary/add-sentiment", data);
  }

  public static updateSentimentKeyword(data: { id: string; value: number }) {
    return this.put("/corpus/dictionary/update-sentiment", data);
  }

  public static deleteSentimentKeyword(id: string) {
    return this.delete("/corpus/dictionary/delete-sentiment", {
      params: {
        "sentiment-id": id,
      },
    });
  }

  // pantip tags
  public static getPantipTags({ page = 0, limit = 10, q }) {
    return this.query("/crawler-tool-server/pantip/get-tags", {
      params: { page, limit, q },
    });
  }

  public static addPantipTag(data: { tag: string }) {
    return this.post("/crawler-tool-server/pantip/add-tag", data);
  }

  public static deletePantipTag(_id: string) {
    return this.delete("/crawler-tool-server/pantip/delete-tag", {
      params: {
        _id,
      },
    });
  }

  // tiktok users
  public static getTiktokUsers({ page = 0, limit = 10, q }) {
    return this.query("/crawler-tool-server/tiktok-users/get", {
      params: { page, limit, q },
    });
  }

  public static addTiktokUser(data: { link: string }) {
    return this.post("/crawler-tool-server/tiktok-users/add", data);
  }

  public static deleteTiktokUser(_id: string) {
    return this.delete("/crawler-tool-server/tiktok-users/" + _id, {
      params: {
        _id,
      },
    });
  }

  public static getAllFanpagesInfo() {
    return this.get("/fanpage/get-all-fanpage");
  }

  public static searchFanpage(data: {
    id?: string;
    name?: string;
    type?: string;
    page?: number;
    sortDirection?: string;
    sortField?: string;
    sizePerPage?: number;
  }) {
    return this.post("/corpus/fanpage/search", data);
  }

  public static setTypeFanpage(data: { id: string; type: string }) {
    return this.put(
      `/corpus/fanpage/set-fanpage-type?fanpage-id=${data.id}&fanpage-type=${data.type}`,
      {}
    );
  }

  public static addDefaultFanpagesToAccount(data: { id: string }) {
    return this.put(
      `/corpus/fanpage/add-default-fanpage-account?account-id=${data.id}`,
      {}
    );
  }

  public static resolveFanpageUrl(data: { fanpageurl?: string }) {
    return this.get(
      `/corpus/fanpage/resolve-fanpage-url?fanpage-url=${data.fanpageurl}&type=default`
    );
  }
  public static alreadyResolveFanpageUrl(data: { fanpageurl?: string }) {
    return this.get(
      `/corpus/fanpage/already-resolve-fanpage-url?fanpage-url=${data.fanpageurl}`
    );
  }
  public static exportFanpage() {
    return this.post(`/corpus/fanpage/export`, "", {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
      maxBodyLength: Infinity,
    });
  }
  public static importFanpage({ fliefanpage }) {
    const data = new FormData();
    data.append("files", fliefanpage);
    return this.post(`/corpus/fanpage/import`, data);
  }

  public static clearCacheAccount(accountId?: string) {
    return this.delete(`/analyticbasic/cache?accountId=${accountId}`);
  }

  public static searchInfluencer(data) {
    return this.post(`/corpus/basic/influencer`, data);
  }
  public static updateInfluencer(data) {
    return this.post(
      `/corpus/basic/influencer/update-influencer?userId=${data.id}`,
      {
        interests: data.interests,
        audienceSize: data.audienceSize,
        type: data.type,
      }
    );
  }
  public static searchInfluencerUsername(data) {
    return this.get(
      `/analytic/influencer-username/search?username=${data.username}`
    );
  }
  public static addInfluencer(data) {
    return this.post(`/corpus/basic/influencer/add-influencer`, data);
  }
  public static deleteInfluencer(data) {
    return this.delete(
      `/corpus/basic/influencer/remove-influencer?userId=${data}`
    );
  }

  public static getInterestsList() {
    return this.get(`/corpus/basic/influencer/get-predefined-interests`);
  }

  public static getCentralExcludeKeyword(excludeAccounts: string[]) {
    const excludeAccountsParam = excludeAccounts.join(",");
    return this.get(
      `/corpusV2/central-exclude-keyword/get-all-keyword?excludeAccounts=${excludeAccountsParam}`
    );
  }
  public static addCentralExcludeKeyword(data) {
    return this.post(`/corpusV2/central-exclude-keyword/add-keyword`, data);
  }
  public static updateCentralExcludeKeyword(data) {
    return this.post(`/corpusV2/central-exclude-keyword/update-keyword`, data);
  }
  public static deleteCentralExcludeKeyword(id: string) {
    return this.delete(`/corpusV2/central-exclude-keyword/remove-keyword`, {
      params: {
        id: id,
      },
    });
  }

  // IMAGE OCR
  public static ocrByUrl(link: string) {
    return this.post("/ocr/link", {
      url: link,
    });
  }
  public static ocrByFile(fileOcr: File) {
    const data = new FormData();
    data.append("image", fileOcr);
    return this.post("/ocr/file", data);
  }
}

export default ApiService;
